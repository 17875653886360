import React, { useContext, useEffect, useState } from "react";
import UserContext from "../../../contexts/UserContext";
import LangContext from "../../../contexts/LangContext";
import UserComment from "../UserComment";
import { Typography } from "@mui/material";
import { BASE_URL } from "../../../theme";

const CommentFeed = ({ type }) => {
    const { userId } = useContext(UserContext);
    const { langTokens } = useContext(LangContext);
    const [comments, setComments] = useState([]);

    useEffect(() => {
        const fetchComments = async () => {
            try {
                const res = await fetch(
                    `${BASE_URL}/comments/user/${userId}/?filter=${type}`,
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${localStorage.getItem(
                                "access"
                            )}`,
                        },
                    }
                );

                const data = await res.json();

                setComments(data.results);
            } catch (error) {
                console.error("Error fetching comments:", error);
            }
        };
        fetchComments();
    }, [type, userId]);

    const delComment = (id) => {
        fetch(`${BASE_URL}/comments/del/${id}/`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("access")}`,
            },
        })
            .then(async (res) => {
                if (res.status !== 204) {
                    return Promise.reject(res);
                }
                setComments(comments.filter((c) => c.id !== id));
            })
            .catch((err) => console.log(err));
    };

    return (
        <div
            className="user-comment-feed"
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}>
            {type === "rejected" && (
                <Typography fontSize={11}>
                    {langTokens.rejected_note}
                </Typography>
            )}
            {comments.map((comment, i) => (
                <UserComment
                    key={"comment-" + i}
                    comment={comment}
                    delComment={delComment}
                />
            ))}
        </div>
    );
};

export default CommentFeed;
