import React, { useContext, useEffect, useReducer, useState } from "react";
import LoadingContext from "../../contexts/LoadingContext";
import LoadingScreen from "../../components/generic/LoadingScreen";
import { useNavigate, useParams } from "react-router-dom";
import {
    Card,
    CardContent,
    CardHeader,
    Typography,
    Avatar,
    CardActions,
    IconButton,
} from "@mui/material";
import { BASE_URL, CARD_WIDTH } from "../../theme";
import "./singlePost.css";
import VerseChip from "../../components/generic/VerseChip";
import OptionsDropdown from "../../components/generic/OptionsDropdown";
import UserContext from "../../contexts/UserContext";
import { createRanges } from "../../components/posts/VersePost";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import ChaptersContext from "../../contexts/ChaptersContext";
import CommentThread from "../../components/comments/CommentThread";
import CommentThreadForm from "../../components/comments/CommentThreadForm";
import EditPostForm from "../../components/posts/EditPostForm";
import LangContext from "../../contexts/LangContext";

const commReducer = (state, action) => {
    switch (action.type) {
        case "SET_FIELD":
            return { ...state, [action.field]: action.value };

        default:
            return state;
    }
};

const PostPage = () => {
    const { id } = useParams();

    const [post, setPost] = useState(null);
    const [postComments, setPostComments] = useState([]);
    const [ranges, setRanges] = useState([]);
    const [likes, setLikes] = useState([]);
    const [dislikes, setDislikes] = useState([]);
    const [displayEdit, setDisplayEdit] = useState(false);

    const { isLoading, setIsLoading } = useContext(LoadingContext);
    const { chapters, fetchChapters } = useContext(ChaptersContext);
    const { userId } = useContext(UserContext);
    const { langTokens } = useContext(LangContext);

    let navigate = useNavigate();
    const [commentState, commentDispatch] = useReducer(commReducer, {
        liked: [],
        disliked: [],
        numLikes: {},
        numDislikes: {},
        submitted: false,
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
            const response = await fetch(
                `${BASE_URL}/posts/id/${id}/`
            );
            

            const data = await response.json();
            return data;
        }
        catch (error) {
            console.error("Error fetching post", error);
        }

        }
        const fetchPost = async () => {
            try {
                setIsLoading(true);
                commentDispatch({
                    type: "SET_FIELD",
                    field: "submitted",
                    value: false,
                });
               

                if (chapters.length === 0) {
                    fetchChapters();
                }

                const data = await fetchData();

                createRanges(data, setRanges);


                setPost(data);
                setLikes(data.likes.map((l) => l.user));
                setDislikes(data.dislikes.map((d) => d.user));
                setPostComments(data.comments);
                
                // start of mod feature
                // setPostComments(
                //     data.comments.filter(
                //         (c) =>
                //             c.reviews.filter((r) => r.approved === true)
                //                 .length > 1
                //     )
                // );
                // end of mod feature

                setIsLoading(false);
            } catch (err) {
                console.error("Error fetching data: ", err);
            }
        };

        fetchPost();
    }, [id, chapters, commentState.submitted, fetchChapters, setIsLoading]);

    const likePost = async () => {
        await fetch(`${BASE_URL}/posts/like/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("access")}`,
            },
            body: JSON.stringify({
                post: post.id,
                user: userId,
            }),
        })
            .then(async (res) => {
                if (res.status !== 201) {
                    return Promise.reject(res);
                } else {
                    setLikes([...likes, userId]);
                }
            })
            .catch((err) => console.log(err));
    };

    const dislikePost = () => {
        fetch(`${BASE_URL}/posts/dislike/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("access")}`,
            },
            body: JSON.stringify({
                post: post.id,
                user: userId,
            }),
        })
            .then(async (res) => {
                if (res.status !== 201) {
                    return Promise.reject(res);
                } else {
                    setDislikes([...dislikes, userId]);
                }
            })
            .catch((err) => console.log(err));
    };

    const removeLike = async () => {
        await fetch(`${BASE_URL}/posts/del/like/`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("access")}`,
            },
            body: JSON.stringify({
                post: post.id,
                user: userId,
            }),
        })
            .then(async (res) => {
                if (res.status !== 204) {
                    return Promise.reject(res);
                } else {
                    setLikes(likes.filter((u) => u !== userId));
                }
            })
            .catch((err) => console.log(err));
    };

    const removeDislike = () => {
        fetch(`${BASE_URL}/posts/del/dislike/`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("access")}`,
            },
            body: JSON.stringify({
                post: post.id,
                user: userId,
            }),
        })
            .then(async (res) => {
                if (res.status !== 204) {
                    return Promise.reject(res);
                } else {
                    setDislikes(dislikes.filter((u) => u !== userId));
                }
            })
            .catch((err) => console.log(err));
    };

    const delPost = () => {
        fetch(`${BASE_URL}/posts/del/${post.id}/`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("access")}`,
            },
        })
            .then(async (res) => {
                if (res.status !== 204) {
                    return Promise.reject(res);
                }
            })
            .catch((err) => console.log(err));
    };

    return (
        <div className="post-page">
            {isLoading ? (
                <LoadingScreen />
            ) : (
                <div className="single-post">
                    {post && (
                        <Card
                            sx={{
                                width: CARD_WIDTH,
                                margin: "1rem",
                            }}>
                            <CardHeader
                                align="left"
                                avatar={
                                    <Avatar sx={{ color: "deepOrange" }}>
                                        {post.user["username"][0].toUpperCase()}
                                    </Avatar>
                                }
                                title={
                                    <>
                                        <Typography fontSize={14}>
                                            {post.user["username"] +
                                                " • " +
                                                new Date(post.date_created)
                                                    .toDateString()
                                                    .slice(4)}
                                        </Typography>
                                        {post.user.bio &&
                                            post.user.subtitle !== "" && (
                                                <Typography
                                                    fontSize={12}
                                                    fontStyle={"italic"}
                                                    color="gray.main">
                                                    {post.user.bio.subtitle}
                                                </Typography>
                                            )}
                                    </>
                                }
                                action={
                                    post.user["id"] === userId && (
                                        <OptionsDropdown
                                            options={[
                                                {
                                                    label: langTokens.edit,
                                                    onClick: () => {
                                                        setDisplayEdit(true);
                                                    },
                                                },
                                                {
                                                    label: langTokens.delete,
                                                    onClick: delPost,
                                                },
                                            ]}
                                        />
                                    )
                                }
                            />

                            {displayEdit ? (
                                <EditPostForm
                                    post={post}
                                    setDisplayForm={setDisplayEdit}
                                />
                            ) : (
                                <>
                                    <CardContent
                                        align="left"
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                        }}>
                                        <Typography
                                            fontSize={14}
                                            sx={{ whiteSpace: "pre-wrap" }}>
                                            {post.text !== undefined &&
                                                post.text}
                                        </Typography>

                                        <div
                                            style={{
                                                display: "inline-flex",
                                                paddingBottom: "0",
                                                paddingTop: "0.3rem",
                                            }}>
                                            {ranges.map((range, i) => (
                                                <VerseChip
                                                    key={"chip" + i}
                                                    range={range}
                                                />
                                            ))}
                                        </div>
                                    </CardContent>
                                    <CardActions disableSpacing>
                                        <Typography>
                                            {!likes.includes(userId) && (
                                                <IconButton
                                                    onClick={() =>
                                                        userId === 0
                                                            ? navigate("/login")
                                                            : likePost()
                                                    }>
                                                    <ThumbUpAltIcon />
                                                </IconButton>
                                            )}
                                            {likes.includes(userId) && (
                                                <IconButton
                                                    onClick={() =>
                                                        userId === 0
                                                            ? navigate("/login")
                                                            : removeLike()
                                                    }>
                                                    <ThumbUpAltIcon color="primary" />
                                                </IconButton>
                                            )}
                                            {likes.length}

                                            {!dislikes.includes(userId) && (
                                                <IconButton
                                                    onClick={() =>
                                                        userId === 0
                                                            ? navigate("/login")
                                                            : dislikePost()
                                                    }>
                                                    <ThumbDownAltIcon />
                                                </IconButton>
                                            )}
                                            {dislikes.includes(userId) && (
                                                <IconButton
                                                    onClick={() =>
                                                        userId === 0
                                                            ? navigate("/login")
                                                            : removeDislike()
                                                    }>
                                                    <ThumbDownAltIcon color="primary" />
                                                </IconButton>
                                            )}
                                            {dislikes.length}
                                        </Typography>
                                    </CardActions>
                                </>
                            )}
                            <CardContent className="comment-section">
                                <Typography style={{ marginRight: "auto" }}>
                                    {langTokens.comments}
                                </Typography>
                                <CommentThreadForm
                                    postId={post.id}
                                    dispatch={commentDispatch}
                                />
                                <CommentThread
                                    state={commentState}
                                    dispatch={commentDispatch}
                                    inputComments={postComments}
                                    setup={true}
                                />
                            </CardContent>
                        </Card>
                    )}
                </div>
            )}
        </div>
    );
};

export default PostPage;
