import {
    Avatar,
    Card,
    CardContent,
    IconButton,
    Tooltip,
    Typography,
    Alert,
    Box,
    Button,
} from "@mui/material";
import React, { useContext, useState, useEffect } from "react";
import OptionsDropdown from "../../generic/OptionsDropdown";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import ReplyIcon from "@mui/icons-material/Reply";
import UserContext from "../../../contexts/UserContext";
import "./comment.css";
import { BASE_URL, CARD_WIDTH } from "../../../theme";
import { createRanges } from "../../posts/VersePost";
import VerseChip from "../../generic/VerseChip";
import CommentThreadForm from "../CommentThreadForm";
import CommentThread from "../CommentThread";
import { useNavigate } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import LangContext from "../../../contexts/LangContext";

const Comment = ({ comment, delComment, state, dispatch, margin = 0 }) => {
    const { userId } = useContext(UserContext);
    const { langTokens } = useContext(LangContext);
    const [displayForm, setDisplayForm] = useState(false);
    const [displayThread, setDisplayThread] = useState(false);

    const [success, setSuccess] = useState(false);
    const [ranges, setRanges] = useState([]);

    const { liked, disliked, numLikes, numDislikes } = state;

    let navigate = useNavigate();

    useEffect(() => {
        createRanges(comment, setRanges);
    }, [comment]);

    const likeComm = (id) => {
        fetch(`${BASE_URL}/comments/like/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("access")}`,
            },
            body: JSON.stringify({
                comment: id,
                user: userId,
            }),
        })
            .then(async (res) => {
                if (res.status !== 201) {
                    return Promise.reject(res);
                } else {
                    dispatch({
                        type: "SET_FIELD",
                        field: "liked",
                        value: [...liked, id],
                    });

                    dispatch({
                        type: "SET_FIELD",
                        field: "numLikes",
                        value: {
                            ...numLikes,
                            [id]: numLikes[id] + 1,
                        },
                    });
                }
            })
            .catch((err) => console.log(err));
    };

    const removeLike = (id) => {
        fetch(`${BASE_URL}/comments/del/like/`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("access")}`,
            },
            body: JSON.stringify({
                comment: id,
                user: userId,
            }),
        })
            .then(async (res) => {
                if (res.status !== 204) {
                    return Promise.reject(res);
                } else {
                    dispatch({
                        type: "SET_FIELD",
                        field: "liked",
                        value: liked.filter((c) => c !== id),
                    });

                    dispatch({
                        type: "SET_FIELD",
                        field: "numLikes",
                        value: {
                            ...numLikes,
                            [id]: numLikes[id] - 1,
                        },
                    });
                }
            })
            .catch((err) => console.log(err));
    };

    const dislikeComm = (id) => {
        fetch(`${BASE_URL}/comments/dislike/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("access")}`,
            },
            body: JSON.stringify({
                comment: id,
                user: userId,
            }),
        })
            .then(async (res) => {
                if (res.status !== 201) {
                    return Promise.reject(res);
                } else {
                    dispatch({
                        type: "SET_FIELD",
                        field: "disliked",
                        value: [...disliked, id],
                    });

                    dispatch({
                        type: "SET_FIELD",
                        field: "numDislikes",
                        value: {
                            ...numDislikes,
                            [id]: numDislikes[id] + 1,
                        },
                    });
                }
            })
            .catch((err) => console.log(err));
    };

    const removeDislike = (id) => {
        fetch(`${BASE_URL}/comments/del/dislike/`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("access")}`,
            },
            body: JSON.stringify({
                comment: id,
                user: userId,
            }),
        })
            .then(async (res) => {
                if (res.status !== 204) {
                    return Promise.reject(res);
                } else {
                    dispatch({
                        type: "SET_FIELD",
                        field: "disliked",
                        value: disliked.filter((c) => c !== id),
                    });

                    dispatch({
                        type: "SET_FIELD",
                        field: "numDislikes",
                        value: {
                            ...numDislikes,
                            [id]: numDislikes[id] - 1,
                        },
                    });
                }
            })
            .catch((err) => console.log(err));
    };

    return (
        <div
            className="comment"
            key={comment.id}
            id={`comment-${comment.id}`}
            sx={{ width: CARD_WIDTH }}>
            <Box className="avatar-span">
                <Avatar className="avatar">
                    {comment.user.username[0].toUpperCase()}
                </Avatar>
                <a
                    href={`#comment-${comment.id}`}
                    className="comment-border-link">
                    <span className="sr-only">{`Jump to comment-${comment.id}`}</span>
                </a>
            </Box>
            <div className="card-section">
                <Card
                    key={"comment-" + comment.id}
                    style={{
                        border: "none",
                        boxShadow: "none",
                    }}>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                        }}>
                        <CardContent
                            align="left"
                            style={{ paddingBottom: 0, width: "100%" }}>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}>
                                <Typography
                                    style={{
                                        paddingBottom: "0.5rem",
                                    }}
                                    fontWeight={"bold"}
                                    color="gray.main"
                                    fontSize={13}>
                                    {comment.user.username +
                                        " • " +
                                        new Date(comment.date_created)
                                            .toDateString()
                                            .slice(4)}
                                </Typography>
                                {comment.user.id === userId && (
                                    <OptionsDropdown
                                        style={{ marginLeft: "auto" }}
                                        icon={"VERT"}
                                        options={[
                                            {
                                                label: langTokens.delete,
                                                onClick: () => {
                                                    delComment(comment.id);
                                                },
                                            },
                                        ]}
                                    />
                                )}
                            </Box>
                            <Typography
                                sx={{
                                    whiteSpace: "pre-wrap",
                                    fontSize: 13,
                                }}>
                                {comment.text !== undefined && comment.text}
                            </Typography>

                            <div
                                style={{
                                    display: "inline-flex",
                                    paddingTop: "0.3rem",
                                }}>
                                {ranges.map((range, i) => (
                                    <VerseChip key={"chip" + i} range={range} />
                                ))}
                            </div>

                            <Box
                                sx={{
                                    paddingTop: "0.5rem",
                                }}>
                                {!liked.includes(comment.id) && (
                                    <IconButton
                                        className="icon-button"
                                        onClick={() =>
                                            userId === 0
                                                ? navigate("/login")
                                                : likeComm(comment.id)
                                        }>
                                        <ThumbUpAltIcon />
                                    </IconButton>
                                )}
                                {liked.includes(comment.id) && (
                                    <IconButton
                                        onClick={() =>
                                            userId === 0
                                                ? navigate("/login")
                                                : removeLike(comment.id)
                                        }>
                                        <ThumbUpAltIcon color="primary" />
                                    </IconButton>
                                )}
                                {numLikes[comment.id]}

                                {!disliked.includes(comment.id) && (
                                    <IconButton
                                        className="icon-button"
                                        onClick={() =>
                                            userId === 0
                                                ? navigate("/login")
                                                : dislikeComm(comment.id)
                                        }>
                                        <ThumbDownAltIcon />
                                    </IconButton>
                                )}

                                {disliked.includes(comment.id) && (
                                    <IconButton
                                        onClick={() =>
                                            userId === 0
                                                ? navigate("/login")
                                                : removeDislike(comment.id)
                                        }>
                                        <ThumbDownAltIcon color="primary" />
                                    </IconButton>
                                )}
                                {numDislikes[comment.id]}

                                <Tooltip title={langTokens.reply}>
                                    <IconButton
                                        className="icon-button"
                                        onClick={() => {
                                            userId === 0
                                                ? navigate("/login")
                                                : setDisplayForm(true);
                                        }}>
                                        <ReplyIcon />
                                    </IconButton>
                                </Tooltip>

                                {comment.responses.length > 0 &&
                                    !displayThread && (
                                        <Button
                                            color="inherit"
                                            onClick={() =>
                                                setDisplayThread(true)
                                            }>
                                            <Typography fontSize={13}>
                                                <ExpandMoreIcon />
                                                {langTokens.replies}
                                            </Typography>
                                        </Button>
                                    )}

                                {comment.responses.length > 0 &&
                                    displayThread && (
                                        <Button
                                            color="inherit"
                                            onClick={() =>
                                                setDisplayThread(false)
                                            }>
                                            <Typography fontSize={13}>
                                                <ExpandLessIcon />
                                                {langTokens.replies}
                                            </Typography>
                                        </Button>
                                    )}
                            </Box>
                        </CardContent>
                    </div>
                </Card>

                {/* start of mod feature */}
                {/* {success && (
                    <Alert
                        severity="success"
                        style={{ margin: "0.8rem" }}
                        onClose={() => {
                            setSuccess(false);
                        }}>
                        {langTokens.comment_alert}
                    </Alert>
                )} */}
                {/* end of mod feature */}

                {displayForm && (
                    <CommentThreadForm
                        nested={true}
                        dispatch={dispatch}
                        postId={comment.post}
                        parent={comment.id}
                        displayFunc={setDisplayForm}
                        successFunc={setSuccess}
                    />
                )}

                {displayThread && comment.responses.length > 0 && (
                    <CommentThread
                        key={"c-thread-" + comment.id}
                        inputComments={comment.responses}
                        state={state}
                        dispatch={dispatch}
                        margin={margin + 5}
                    />
                )}
            </div>
        </div>
    );
};

export default Comment;
