import React, { createContext, useCallback, useState } from "react";
import { BASE_URL } from "../theme";

const ChaptersContext = createContext({
    chapters: [],
    setChapters: () => {},
    chapterNames: {},
    chapterOptions: {},
    fetchChapters: () => {},
});

export const ChaptersProvider = ({ children }) => {
    const [chapters, setChapters] = useState([]);
    const [chapterNames, setChapterNames] = useState({});
    const [chapterOptions, setChapterOptions] = useState({});

    const fetchChapters = useCallback(async () => {
        try {
            const response = await fetch(
                `${BASE_URL}/quran/all/?limit=114`
            );
            const data = await response.json();
            setChapters(data.results);

            setChapterNames(
                Object.fromEntries(
                    data.results.map((c) => [c.id, [c.name_eng, c.name_ara]])
                )
            );

            setChapterOptions(
                data.results.map((c) => ({
                    chapter: c,
                    label: c.num + ". " + c.name_eng + " (" + c.name_ara + ")",
                }))
            );
        } catch (error) {
            console.error("Error fetching chapters:", error);
        }
    }, []);

    return (
        <ChaptersContext.Provider
            value={{
                chapters,
                chapterNames,
                chapterOptions,
                fetchChapters,
            }}>
            {children}
        </ChaptersContext.Provider>
    );
};

export default ChaptersContext;
