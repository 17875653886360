import React, { useContext, useEffect } from "react";
import ChaptersContext from "../../../contexts/ChaptersContext";
import { Link } from "react-router-dom";
import { Chip, Typography } from "@mui/material";
import LangContext from "../../../contexts/LangContext";

const VerseChip = ({ range }) => {
    const { chapterNames, fetchChapters } = useContext(ChaptersContext);
    const { lang } = useContext(LangContext);

   
    // useEffect(() => {
    //     if (Object.keys(chapterNames).length === 0) {
    //         console.log("inside if");
    //         fetchChapters();
    //     }
    // }, []);

    return (
        <div
            style={{
                paddingRight: "0.3rem",
            }}>
            <Chip
                size="small"
                variant="outlined"
                color="primary"
                label={
                    <Typography
                        fontWeight={"500"}
                        fontSize={12}
                        fontStyle={"italic"}>
                        {Object.keys(chapterNames).length !== 0 && `${
                            lang === "en"
                                ? chapterNames[range[0]][0]
                                : chapterNames[range[0]][1]
                        } ${range[1]} ${
                            range[1] !== range[2] ? "–" + range[2] : ""
                        }`}
                    </Typography>
                }
                component={Link}
                to={"/quran/" + range[0] + "/verse/" + range[1]}
                clickable
            />
        </div>
    );
};

export default VerseChip;
